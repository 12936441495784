import axios, { ResDataType } from "./ajax";


interface Option {
  ID: number;
  Value: string;
}


export const fetchOptions = async (): Promise<Option[]> => {
  const url = 'https://prod-37.southeastasia.logic.azure.com/workflows/d86b9ed4bede4357b2563289fe63cfa5/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=5DfxJlo6yPOLRs_8MzsxREeKDk0IQgRRV16FDJD842w';
  try {
    const response = await axios.get(url);
    return response.data.map((item: { ID: number; Value: string }) => ({
      ID: item.ID,
      Value: item.Value,
    }));
  } catch (error) {
    console.error('获取选项失败:', error);
    return [];
  }
};

// 注册用户
export async function registerService(
  email: string,
  code: string,
  key: string,
  selectedOption: string | undefined
): Promise<ResDataType> {
  const url = 'https://prod-07.southeastasia.logic.azure.com:443/workflows/191b5e3eb3fa4a4b97ca3a693b764a77/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=Bi9FK9ycp3O6a_atue--VfCositGKDrB85M0ruQKdPI';

  const response = await axios.post(url, {
    email, code, key, selectedOption
  });
  if (response.status === 200) {
    return response.data as ResDataType;
  } else if (response.status !== 200) {  // 检查状态码是否为201，如果是，则抛出错误
    throw new Error(JSON.stringify({
      status: response.status,
      data: response.data
    }));
  }
  return response.data as ResDataType;

}

//发送校验码申请
export async function sendVerificationCode(
  email: string,
  key: string
): Promise<ResDataType> {
  const url = "https://prod-06.southeastasia.logic.azure.com:443/workflows/3a0c684ee4fe487e830491cbe43566e6/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=bIj_yJHuOLaVBtiC3z5K0kzc4pTkjgY86Ngv9J2rIXY";
  const body = { email, key };
  const response = (await axios.post(url, body)) as ResDataType;
  //  
  if (response.status !== 200) {
    throw new Error(JSON.stringify({
      status: response.status,
      data: response.data
    }));
  }
  return response.data as ResDataType;
}