import React from "react";
import { createBrowserRouter } from "react-router-dom";

import MainLayout from "../layouts/MainLayout";
import Home from "../pages/Home";
import Error from "../pages/Error"
import Success from "../pages/Success"
import NotFound from '../pages/NotFound'
import CoolDown from '../pages/CoolDown'


const router = createBrowserRouter([
  {
    path: "/",
    element: <MainLayout />,
    children: [
      {
        path: "/:urlid?",
        element: <Home />,
      },
      {
        path: 'error',
        element: <Error />,
      },
      {
        path: 'cooldown',
        element: <CoolDown />,
      },
      {
        path: 'success',
        element: <Success />,
      },
      {
        path: "*", // 404 路由配置，都写在最后（兜底）
        element: <NotFound />,
      },
    ],
  },
]);

export default router;

// ------------ 分割线 ------------

// 常用的路由，常量
export const HOME_PATHNAME = "/";
export const ERROR_PATHNAME = "/error";
export const SUCCESS_PATHNAME = "/success";
export const COOLDOWN_PATHNAME = "/cooldown";
export const MANAGE_INDEX_PATHNAME = "/";


export function isNoNeedUserInfo(pathname: string) {
  if ([HOME_PATHNAME, SUCCESS_PATHNAME, ERROR_PATHNAME].includes(pathname))
    return true;
  return false;
}
