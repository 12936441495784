import React, { FC, useState, useEffect } from "react";
import {
  Typography,
  Space,
  Form,
  Input,
  Button,
  message,
  Checkbox,
  Card,
  Modal,
  Row, Col, Select
} from "antd";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useRequest } from "ahooks";
import { ERROR_PATHNAME, SUCCESS_PATHNAME, COOLDOWN_PATHNAME } from "../router";
import { debounce } from "lodash";
import { SendOutlined } from '@ant-design/icons';
import { registerService, sendVerificationCode, fetchOptions } from "../services/user";
import styles from "./Home.module.scss";
import logo from "./logo.png";

const { Title } = Typography;

const Register: FC = () => {
  const location = useLocation();
  const [form] = Form.useForm();
  const code = Form.useWatch('code', form);
  const key = "1234578454dsdv";
  const nav = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const privacyContent = `
  <h2>由组织提供的产品 - 最终用户声明</h2>

  <p>如果你使用所属组织提供的账户使用 Microsoft 产品，例如工作或学校账户，组织可：</p>

  <ul>
    <li>控制和管理你的 Microsoft 产品和产品帐户，包括控制产品或产品帐户的隐私相关设置。</li>
    <li>访问和处理你的数据，包括交互数据、诊断数据和与 Microsoft 产品和产品帐户关联的通信和文件的内容。</li>
  </ul>

  <p>如果你无法访问你的工作或学校帐户（例如，如果发生工作变更），则在你使用过你的工作或学校帐户登录到产品的情况下，你可能无法访问此类产品以及与这些产品关联的内容，包括你为自己获取的产品或内容。</p>

  <p>许多 Microsoft 产品供学校和企业之类的组织使用。 请参阅本隐私声明的企业和开发人员产品部分。 如果组织为你提供了 Microsoft 产品的访问权限，则使用 Microsoft 产品时应遵守贵组织的政策（如果有）。 你应该向组织管理员提出隐私查询要求，包括任何行使数据保护权利的请求。 当你使用 Microsoft 产品中的社交功能时，你网络中的其他用户可能会看到你的某些活动。 若要了解有关社交功能和其他功能的详细信息，请查看特定于 Microsoft 产品的文档或帮助内容。 客户的隐私或安全政策可能与本隐私声明中阐述的隐私或安全政策有所不同，Microsoft 对客户的隐私或安全政策不负任何责任。</p>

  <h2>我们收集的个人数据</h2>

  <p>出于下述各种目的，包括高效运营以及为你提供最佳活动体验，WYMEC 会收集你向我们提供的数据、你与 WYMEC 之间的互动数据以及产品使用数据。 你直接提供部分此类数据，例如当你创建 WYMEC 帐户、报名活动、提交作品、查询证书或需要获得我们的支持时。 其他数据则由我们通过你与系统的交互、对系统的使用和体验以及相关通信收集而来。</p>

  <p>我们收集的数据可能包括以下各项：</p>

  <ul>
    <li><strong>姓名和联系人数据.</strong> 你的姓氏和名字、身份证或其他身份证明信息、电子邮件地址、通信地址、电话号码以及其他类似的联系人数据。</li>
    <li><strong>凭据.</strong> 密码、密码提示以及用于验证和帐户访问的类似安全信息。</li>
    <li><strong>统计数据.</strong> 与你有关的数据，如你的年龄、性别、所在国家/地区和首选语言。</li>
    <li><strong>交互.</strong> 有关你的 WYMEC 参与活动情况的数据。 在某些情况下，如搜索查询时，此类数据由你出于参与活动的目的而提供。 在其他情况下，如报告错误时，此类数据由我们生成。</li>
  </ul>
`;
  //按键防抖功能
  const [cooldown, setCooldown] = useState(false);
  const [counter, setCounter] = useState(60);
  const [email, setEmail] = useState("");

  const [registerCooldown, setRegisterCooldown] = useState(false);
  const [registerCounter, setRegisterCounter] = useState(10); // 设置注册按钮的冷却时间为10秒
  // 注册按钮的防抖函数
  const handleRegister = debounce(
    async (values: any) => {
      if (!registerCooldown) {
        console.log("Form values:", values);
        const { email, code } = values;
        if (!code) {
          message.error('请输入验证码');
          return;
        }
        if (!selectedOption) {
          message.error('请选择参赛选题');
          return;
        }
        await run({ email, code, key, selectedOption });
        setRegisterCooldown(true);
        setRegisterCounter(10);
      }
    },
    1000,
    { leading: true, trailing: false }
  );

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (registerCooldown) {
      timer = setInterval(() => {
        setRegisterCounter((prevCounter) => {
          if (prevCounter === 1) {
            clearInterval(timer);
            setRegisterCooldown(false);
            return 10;
          }
          return prevCounter - 1;
        });
      }, 1000);
    }

    return () => timer && clearInterval(timer);
  }, [registerCooldown]);

  const { run: sendCode } = useRequest(
    (email: string) => sendVerificationCode(email, key),
    {
      manual: true,
      onSuccess: (data) => {
        message.success('验证码发送成功');
        setCooldown(true);
        setCounter(60);
      },
      onError(error: any) {
        try {
          const errorData = JSON.parse(error.message);
          switch (errorData.status) {
            case 201:
              message.error('已申请过校验码，30分钟内有效，请勿重复申请!');
              break;
            case 202:
              message.error('邮箱已注册，请更换邮箱!');
              break;
            case 500:
              message.error('服务器内部错误，请稍后重试');
              break;
            default:
              message.error('发送验证码失败，请稍后重试');
          }
        } catch {
          message.error('发送验证码失败，请检查您的网络连接');
        }
      },
    }
  );

  // 防抖发起搜索请求和启动倒计时
  const handleSendCode = debounce(
    async () => {
      if (!cooldown && email !== "") {
        sendCode(email);
      }
    },
    1000,
    { leading: true, trailing: false }
  );
  const { urlid } = useParams<{ urlid?: string }>();
  const [selectedOption, setSelectedOption] = useState<string | undefined>(undefined);

  interface Option {
    ID: number;
    Value: string;
  }

  const [options, setOptions] = useState<Option[]>([]);
  const [isSelectDisabled, setIsSelectDisabled] = useState(false);
  useEffect(() => {
    const getOptions = async () => {

      const formattedOptions = await fetchOptions();
      setOptions(formattedOptions);

      // 如果有ID参数，找到对应的选项并设置
      if (urlid) {
        const matchedOption = formattedOptions.find(option => option.ID.toString() === urlid);
        if (matchedOption) {
          setSelectedOption(matchedOption.ID.toString());
          setIsSelectDisabled(true); // 如果 urlid 有值并且匹配到选项，则锁定
        }
      } else {
        setIsSelectDisabled(false); // 如果 urlid 没有值，则不锁定
      }
    };
    getOptions();
  }, [urlid]);

  useEffect(() => {
    let timer: NodeJS.Timeout; // Declare timer as NodeJS.Timeout

    if (cooldown) {
      timer = setInterval(() => {
        setCounter((prevCounter) => {
          if (prevCounter === 1) {
            clearInterval(timer);
            setCooldown(false); // 冷却结束
            return 60;
          }
          return prevCounter - 1;
        });
      }, 1000);
    }

    return () => timer && clearInterval(timer); // 清理定时器
  }, [cooldown]);

  //获取第一列的地址值

  const { run } = useRequest(
    async (values: { email: string; code: string; key: string; selectedOption: string | undefined }) => {
      const { email, code, key, selectedOption } = values;
      return await registerService(email, code, key, selectedOption);
    },
    {
      manual: true,
      onSuccess(data) {
        message.success('');
        nav(SUCCESS_PATHNAME, { state: { registerData: data } });
      },
      onError(error: any) {
        try {
          const errorData = JSON.parse(error.message);
          switch (errorData.status) {
            case 201:
              message.error('校验码错误，请检查校验码是否正确！');
              break;
            case 202:
              message.error('邮箱已注册，请更换邮箱!');
              break;
            case 500:
              message.error('服务器内部错误，请稍后重试');
              break;
            default:
              message.error('发送验证码失败，请稍后重试');
          }
        } catch {
          message.error('发送验证码失败，请检查您的网络连接');
        }
      },
    }
  );

  const onFinish = (values: any) => {
    console.log("Form values:", values);
    const { email, code } = values;
    if (!code) {
      message.error('请输入验证码');
      return;
    }
    if (!selectedOption) {
      message.error('请选择参赛选题');
      return;
    }
    run({ email, code, key, selectedOption });
  };

  return (
    <div className={styles.container}>
      <div className="register-container">
        <Card className={styles.card}
          style={{ borderRadius: 0 }}>
          {/* You can place your logo here */}
          <img src={logo} alt="logo" className="logo" width={116} height={30} />
          <div>
            <Space
              color="#1677ff"
              style={{ width: "100%", justifyContent: "center" }}
            >
              <Title level={2}>账号密码注册</Title>
            </Space>
          </div>
          <Form
            wrapperCol={{ span: 24 }}
            onFinish={handleRegister}
            // onFinish={onFinish}
            className="createCustomer"
          >
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  type: "email",
                  message: "请输入正确的邮箱地址",
                },
              ]}
            >
              <Input
                placeholder="请输入邮箱"
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Item>

            <Form.Item
            >
              <Select
                showSearch
                placeholder="【请选择参赛选题】"
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                options={options.map(option => ({ value: option.ID.toString(), label: option.Value }))}
                loading={options.length === 0}
                value={selectedOption}
                onChange={(value) => {
                  setSelectedOption(value);
                }}
                disabled={isSelectDisabled}
              />
            </Form.Item>


            <Form.Item
              name="code"
              rules={[
                { required: true, message: '请输入验证码' },
                {
                  pattern: /^\d{6}$/,
                  message: '验证码必须是6位数字'
                }
              ]}
            >
              <Input placeholder="请输入验证码" maxLength={6} />
            </Form.Item>
            <Form.Item>
              <Row justify="center">
                <Col >
                  <Form.Item noStyle>
                    {/* {form.getFieldValue('code')} */}
                  </Form.Item>
                </Col>
                <Col flex="none">
                  <Button type="primary" icon={<SendOutlined />} onClick={handleSendCode} disabled={cooldown || email === ""}>
                    {cooldown ? `等待${counter}秒` : '发送验证码'}
                  </Button>
                </Col>
              </Row>
              <div className={styles.codeHint}>验证码30分钟内有效，请勿重复申请</div>
            </Form.Item>


            <Form.Item
              name="agreement"
              valuePropName="checked"
              rules={[
                {
                  validator: (_, value) =>
                    value
                      ? Promise.resolve()
                      : Promise.reject(new Error("请同意用户协议!")),
                },
              ]}
            >
              <Space>
                <Checkbox></Checkbox>
                <Button type="link" onClick={() => setModalOpen(true)}>
                  我已阅读并同意用户协议、隐私协议
                </Button>
                <Modal
                  title="用户协议、隐私协议"
                  centered
                  open={modalOpen}
                  onOk={() => {
                    setModalOpen(false);
                  }}
                  onCancel={() => setModalOpen(false)}  // 添加这一行
                  okText="已知悉"
                  footer={[
                    <Button
                      key="submit"
                      type="primary"
                      onClick={() => setModalOpen(false)}
                    >
                      已知悉
                    </Button>,
                  ]}
                >
                  <div dangerouslySetInnerHTML={{ __html: privacyContent }} />
                </Modal>
              </Space>
            </Form.Item>

            <Form.Item>
              <Space style={{ width: "100%", justifyContent: "center" }}>
                <Button type="primary" htmlType="submit"  disabled={registerCooldown}>
                {registerCooldown ? `请等待${registerCounter}秒` : '注册'}
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </Card>
      </div>
    </div>
  );
};

export default Register;
