import React, { FC } from "react";
import { Outlet } from "react-router-dom";
import { Layout } from "antd";
import styles from "./MainLayout.module.scss";

const { Content} = Layout;

const MainLayout: FC = () => {
  return (
      <Layout className={styles.main}>
        <Content>
          <Outlet />
        </Content>
      </Layout>
  );
};

export default MainLayout;
