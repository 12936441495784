import React, { FC, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Result, Typography, Button } from "antd";
const { Paragraph, Text } = Typography;

interface RegisterData {
  email: string;
  pwd: string;
}

const Success: FC = () => {
  const location = useLocation();
  const [registerData, setRegisterData] = useState<RegisterData>({
    email: "test@example.com",
    pwd: "testpassword",
  });
  useEffect(() => {
    console.log("Location state:", location.state);
    if (location.state && location.state.registerData) {
      setRegisterData(location.state.registerData);
    }
  }, [location.state]);
  const fullUrl = "https://apps.powerapps.com/play/e/default-0bdeb28d-3f24-4b23-a140-255d4feb23ff/a/4c26e711-2f43-43ab-8013-5ea83f198651?tenantId=0bdeb28d-3f24-4b23-a140-255d4feb23ff&hint=1469b449-a6fd-491c-8b78-dfdab3f89402&sourcetime=1725240646666&hidenavbar=true&source=portal&skipMobileRedirect=1";
  const shortUrl = "https://apps.powerapps.com/...";
  return (
    <Result
      status="success"
      title="恭喜您已成功注册 WYMEC !"
      subTitle="您的登录信息如下："
      extra={[
        <Paragraph key="info">
          {/* {registerData ? ( */}
          <>
            <Text strong>登录账号：</Text> {registerData.email}<br />
            <Text strong>登录密码：</Text> {registerData.pwd}<br />
            <Button
              type="primary"
              href={fullUrl}
              target="_blank"
              rel="noopener noreferrer"
              size="large"
              style={{
                marginTop: '16px',
                color: '#fff',  // 确保文本颜色为白色
                fontSize: '14px',  // 设置字体大小
                fontWeight: 'normal',  // 设置字体粗细
              }}
            >
              立即报名
            </Button>
          </>

          {/* ) : (
            <Text>加载中...</Text>
          )} */}
        </Paragraph>
      ]}
    />
  );
};

export default Success;