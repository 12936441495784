import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import { Result, Button } from "antd";
import { MANAGE_INDEX_PATHNAME } from "../router";

const NotFound: FC = () => {
  const nav = useNavigate();
  return (
    <Result
      title="对不起，您已申请过校验码，30分钟内有效，请勿重复申请!"
      extra={
        <Button type="primary" onClick={() => nav(MANAGE_INDEX_PATHNAME)}>
          返回注册页
        </Button>
      }
    />
  );
};

export default NotFound;
